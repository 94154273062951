export const numberFormat = (number: number, fractals = 2, locale = 'nb-NO'): string => {
    const formatter = Intl.NumberFormat(locale, { minimumFractionDigits: fractals, maximumFractionDigits: fractals });
    if (fractals === 0) {
        return formatter.format(Math.floor(number));
    }
    return formatter.format(number);
};

export const currencyFormatter = (number: number, fractals = 2, locale = 'nb-NO'): string =>
    `${numberFormat(number, fractals, locale)}\xa0kr`;
