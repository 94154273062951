import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useEffect } from 'react';

import { RequestExceptionWithResponse } from '@eika-infrastruktur/request';

import { registerAvdragsfrihetAction, registerContactMe, registerKontaktMeg } from '../actions';
import { AvdragsfrihetParams, KontaktMegParams } from '../generated-types';
import { ErrorTypes } from '../utils/gtm/errorTypes';
import { pushErrorToDataLayer } from '../utils/gtm/gtmService';

export const useAvdragsfrihet = (successCallback: () => void) => {
    const avdragsfrihet = useMutation<void, RequestExceptionWithResponse, AvdragsfrihetParams>({
        mutationKey: ['avdragsfrihet'],
        mutationFn: (contactRequest: AvdragsfrihetParams) => registerAvdragsfrihetAction(contactRequest),
    });
    useEffect(() => {
        if (avdragsfrihet.isSuccess) {
            successCallback();
        }
    }, [avdragsfrihet.isSuccess, successCallback]);
    return avdragsfrihet;
};

export const useContact = (): UseMutationResult<void, RequestExceptionWithResponse, KontaktMegParams> => {
    return useMutation({
        mutationKey: ['kontakt'],
        mutationFn: registerKontaktMeg,
    });
};

export const useContactMe = () =>
    useMutation({
        mutationKey: ['contact-me'],
        mutationFn: registerContactMe,
        onError: (error: Error) => pushErrorToDataLayer(error, ErrorTypes.CUSTOMER_CONTACT_FORM),
    });
