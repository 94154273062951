import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { RequestExceptionWithResponse } from '@eika-infrastruktur/request';

import { registerAcquaintanceForm } from '../actions';
import { AcquaintanceFormParams } from '../generated-types';

const useAcquaintanceForm = (): UseMutationResult<unknown, RequestExceptionWithResponse, AcquaintanceFormParams> =>
    useMutation({
        mutationKey: ['register-acquaintance-form'],
        mutationFn: registerAcquaintanceForm,
    });

export default useAcquaintanceForm;
