import React from 'react';

import { format8DigitPhoneNumber } from '@eika-forsikring/formats';
import { Icon, IconTypes } from '@eika/icon';
import { Link } from '@eika/link';

import { useConfig } from '../../default-config';

import './melde-interesse-footer.scss';

const Footer = () => {
    const { meldinteresseconfig } = useConfig();

    return (
        <>
            <Icon type={IconTypes.TELEFON_24} />
            <p className="melde-interesse-footer__text">Du kan også ringe oss på</p>
            <Link href={`tel: ${meldinteresseconfig.kontakttlf}`}>
                {format8DigitPhoneNumber(meldinteresseconfig.kontakttlf)}
            </Link>
        </>
    );
};

export default Footer;
