import { RequestExceptionWithResponse } from '@eika-infrastruktur/request';

import { ErrorTypes, errorEvent } from './errorTypes';

interface DataLayerVariable {
    [key: string]: unknown;
}

const pushToDataLayer = <T extends DataLayerVariable>(variables: T) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ...variables });
};

export const pushErrorToDataLayer = (error: Error, errorType: ErrorTypes) => {
    const requestException = error as RequestExceptionWithResponse;
    const errorEvent: errorEvent = {
        errorName: errorType,
        errorCode: requestException.code,
        errorDescription: requestException.errorName,
        appNamespace: 'dagligbank-kontakt-meg-web',
    };
    pushToDataLayer({ event: 'error', ...errorEvent });
};
