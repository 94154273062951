import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';

import { Checkbox } from '@eika/checkbox';
import { ExpandableInput } from '@eika/expandable-input';
import { InputLabel } from '@eika/input';

import useTranslation from '../../hooks/useTranslation';

import './melde-interesse-checkbox.scss';

interface MeldeInteresseCheckboxProps {
    onChange: () => void;
    error: FieldError | undefined;
    hideCheckbox?: boolean;
}

export const MAX_LENGTH_FRITEKST_INPUT = 500;
export const MAX_ROWS_FRITEKST_INPUT = 10;

const MeldeInteresseCheckboxAndFritekstfelt = ({
    onChange,
    error,
    hideCheckbox = false,
}: MeldeInteresseCheckboxProps) => {
    const [checked, setChecked] = useState(false);
    const translations = useTranslation();

    return (
        <>
            {!hideCheckbox && (
                <Checkbox
                    className="melde-interesse-checkbox__checkbox"
                    onValueChange={setChecked}
                    checked={checked}
                    label={translations.meldInteresse.form.onskerTilbud}
                />
            )}
            {(checked || hideCheckbox) && (
                <InputLabel
                    className="melde-interesse-checkbox__annet-label"
                    label={translations.meldInteresse.form.other}
                >
                    <ExpandableInput
                        onChange={onChange}
                        error={error?.message}
                        maxLength={MAX_LENGTH_FRITEKST_INPUT}
                        maxRows={MAX_ROWS_FRITEKST_INPUT}
                    />
                </InputLabel>
            )}
        </>
    );
};

export default MeldeInteresseCheckboxAndFritekstfelt;
