import React, { useEffect, useRef, useState } from 'react';

import { postMessageRN, runningInRNWebView, WebToRNAction } from '@eika-infrastruktur/mobilbank-lib';
import { RequestExceptionWithResponse } from '@eika-infrastruktur/request';
import { AttentionMessage, AttentionMessageTypes } from '@eika/attention-message';
import { Button, IconButton } from '@eika/button';
import { IconTypes } from '@eika/icon';
import { RadioButtonPanels } from '@eika/radio-button';

import { LoanDetails } from '../../generated-types';
import useTranslation from '../../hooks/useTranslation';
import { currencyFormatter } from '../../utils/currencyFormatter';

interface LoanPickerProps {
    loans: LoanDetails[];
    selectedAccount?: string;
    setSelectedAccount: (value: string) => void;
    next: () => void;
    prev: () => void;
    request: {
        error?: RequestExceptionWithResponse | null;
        retry: () => void;
    };
}

const getLoanDescription = (balance: number) => `${currencyFormatter(balance, 0)} i lån`;

const LoanPicker: React.FC<LoanPickerProps> = ({ loans, request, selectedAccount, setSelectedAccount, next, prev }) => {
    const translation = useTranslation();
    const loanPickerRef = useRef<HTMLDivElement>(null);

    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    useEffect(() => {
        if (loanPickerRef.current) {
            const el = loanPickerRef.current.querySelector('.sgw-radio-button-legend') as HTMLSpanElement;
            el?.setAttribute('tabindex', '-1');
            el?.focus();
        }
    }, [loanPickerRef]);

    const selectAccount = (value: string) => {
        setErrorMessage(undefined);
        setSelectedAccount(value);
    };

    const goNext = () => {
        if (selectedAccount === undefined) {
            setErrorMessage(translation.avdragsfrihet.form.chooseAccountError);
            return;
        }
        next();
    };

    const { error, retry } = request;

    const hasSomeLoansButError = loans.length > 0 && error;

    const copyError = (textToCopy?: string) => {
        if (!textToCopy) return;
        if (runningInRNWebView) {
            postMessageRN({
                action: WebToRNAction.COPY_TEXT,
                payload: textToCopy,
            });
        } else {
            navigator.clipboard.writeText(textToCopy);
        }
    };

    return (
        <section className="avdragsfrihet-form__content" ref={loanPickerRef}>
            <RadioButtonPanels
                label={translation.avdragsfrihet.form.loanTitle}
                onChange={selectAccount}
                selectedOption={selectedAccount}
                options={loans.map((x) => ({
                    label: x.alias,
                    value: x.accountNumber.value,
                    id: x.accountId.value,
                    description: getLoanDescription(x.balance),
                }))}
                errorMessage={errorMessage}
            >
                {hasSomeLoansButError && (
                    <AttentionMessage
                        type={AttentionMessageTypes.ERROR}
                        text={translation.avdragsfrihet.loanError}
                        clickableItem={
                            <>
                                {error?.rcid && (
                                    <span className="avdragsfrihet-error-label__copy-wrapper">
                                        <span>Feilkode: {error.rcid}</span>
                                        <IconButton
                                            screenReaderText={translation.copyErrorCode}
                                            buttonType="icon-negative"
                                            icon={IconTypes.COPY_24}
                                            onClick={() => copyError(error.rcid)}
                                        />
                                    </span>
                                )}
                                <Button
                                    buttonType="positive"
                                    size="small"
                                    onClick={retry}
                                    icon={IconTypes.RESIRKULER_24}
                                >
                                    {translation.tryAgain}
                                </Button>
                            </>
                        }
                    />
                )}
            </RadioButtonPanels>
            <div className="avdragsfrihet-form__button-group avdragsfrihet-form__item-centered">
                <Button buttonType="negative" onClick={prev}>
                    {translation.avdragsfrihet.form.previous}
                </Button>
                <Button onClick={goNext}>{translation.avdragsfrihet.form.next}</Button>
            </div>
        </section>
    );
};

export default LoanPicker;
