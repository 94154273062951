import { MeldInteresseTextType, useConfig } from '../default-config';

const useMeldInteresseSitecoreTranslation = (): MeldInteresseTextType => {
    const {
        siteLang,
        meldinteresseconfig: { translations },
    } = useConfig();

    return translations[siteLang];
};

export default useMeldInteresseSitecoreTranslation;
