import React, { useEffect, useState } from 'react';

import MeldeInteresseSkjemaModal from '../components/melde-interesse-skjema-modal';
import { useConfig } from '../default-config';

const MeldInteresseApp = () => {
    const [open, setOpen] = useState(false);
    const { meldinteresseconfig } = useConfig();

    useEffect(() => {
        const sitecoreEventListener = () => {
            setOpen(true);
        };

        window.addEventListener(meldinteresseconfig.jseventname, sitecoreEventListener);

        return () => {
            window.removeEventListener(meldinteresseconfig.jseventname, sitecoreEventListener);
        };
    }, [meldinteresseconfig.jseventname]);

    return <MeldeInteresseSkjemaModal open={open} setOpen={setOpen} />;
};

export default MeldInteresseApp;
