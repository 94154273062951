import { get, post } from '@eika-infrastruktur/request';

import {
    AcquaintanceFormParams,
    AvdragsfrihetParams,
    ContactFormParams,
    InteresseskjemaParams,
    KontaktMegParams,
    LoanDetails,
} from '../generated-types';

export const CONTEXT_PATH = '/dagligbank-kontakt-meg-web';
export const SERVICE_PATH = 'rest/resource';
export const FULL_PATH = `${CONTEXT_PATH}/${SERVICE_PATH}`;

export const registerAvdragsfrihetAction = async (params: AvdragsfrihetParams): Promise<void> => {
    const body: AvdragsfrihetParams = {
        ...params,
        appLokasjon: window.location.pathname,
    };
    await post(`${FULL_PATH}/kontakt/avdragsfrihet`, body);
};

export const registerKontaktMeg = async (params: KontaktMegParams): Promise<void> => {
    const body: KontaktMegParams = {
        ...params,
        appLokasjon: window.location.pathname,
    };
    await post(`${FULL_PATH}/kontakt`, body);
};

export const registrerInteresseskjema = async (params: InteresseskjemaParams): Promise<void> => {
    await post(`${FULL_PATH}/meld-interesse`, params);
};

export async function getLoanAccounts(): Promise<LoanDetails[]> {
    return get<LoanDetails[]>(`${FULL_PATH}/loans/accounts`);
}

export const registerContactMe = (params: ContactFormParams) => post(`${FULL_PATH}/kontakt/contactform`, params);

export const registerAcquaintanceForm = (params: AcquaintanceFormParams) =>
    post(`${FULL_PATH}/kontakt/acquaintance-form`, params);
