/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export interface AccountId {
    value: string;
}

export interface AccountNumber {
    value: string;
}

export interface AcquaintanceFormParams {
    bankUsageList: string[];
    bankregnr: Bankregnr;
    email: string;
    importantBankMessage?: string;
    name: string;
    phone: string;
    reasonForNewBank?: string;
    surname: string;
}

export interface AvdragsfrihetParams {
    aarsak: string;
    appLokasjon?: string;
    laan?: string;
    tekst: string;
    varighet: string;
}

export interface Bankregnr extends HarValue<string> {
    value: string;
}

export interface ContactFormParams {
    bankregnr: Bankregnr;
    businessName?: string;
    email: string;
    name: string;
    orgNr?: string;
    ownText?: string;
    phone: string;
    productName: string;
    surname: string;
}

export interface Epost extends HarValue<string> {
    value: string;
}

export interface HarValue<T> {
    value: T;
}

export interface InteresseskjemaParams {
    adresse?: string;
    bankregnr: Bankregnr;
    epost: Epost;
    etternavn: string;
    fornavn: string;
    fritekstfelt?: string;
    produktkode: string;
    prosessomrade: ProcessArea;
    prosesstype: ProcessType;
    tags?: string;
    telefonnummer: Telefonnummer;
}

export interface KontaktMegParams {
    appLokasjon: string;
    tekst: string;
    tema: string;
}

export interface LoanDetails {
    accountId: AccountId;
    accountNumber: AccountNumber;
    alias: string;
    balance: number;
}

export interface Telefonnummer extends HarValue<string> {
    value: string;
}

export enum ProcessArea {
    KREDITT = "KREDITT",
    FORSIKRING = "FORSIKRING",
    SPARING = "SPARING",
    DAGLIGBANK = "DAGLIGBANK",
    FELLES = "FELLES",
    AHV = "AHV",
    DVH = "DVH",
}

export enum ProcessType {
    SALG = "SALG",
    SERVICE = "SERVICE",
    INFO = "INFO",
    KPI = "KPI",
}
