import React from 'react';

import { IconTypes } from '@eika/icon';

import AvdragsfrihetCard from '../components/avdragsfrihet-card';
import { useConfig } from '../default-config';
import useTranslation from '../hooks/useTranslation';

const AdvisorContactBanner: React.FC = () => {
    const translation = useTranslation();
    const { subjects, links } = useConfig();
    const subject = Object.keys(subjects)[0] ?? 'avdragsfrihet';

    const getText = (subject: string) => {
        switch (subject.toLowerCase()) {
            case 'avdragsfrihet':
                return {
                    title: translation.avdragsfrihet.banner.shortTitle,
                    description: translation.avdragsfrihet.banner.shortDescription,
                };
            case 'bedrift':
                return {
                    title: translation.bedrift.title,
                };
            default:
                return {
                    title: translation.contact.title,
                };
        }
    };

    const text = getText(subject);

    return (
        <AvdragsfrihetCard
            title={text.title}
            cardIcon={IconTypes.RADGIVER_DYN}
            link={links.contactLink.url}
            description={text.description}
        />
    );
};

export default AdvisorContactBanner;
