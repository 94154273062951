import { useEffect, useCallback, useMemo } from 'react';

import useQueryParam from './useQueryParam';

const useAvdragsParam = (): { stage?: number; duration?: string; loanAccount?: string } | undefined => {
    const [stage, setStage] = useQueryParam('stage');
    const [loanAccount, setLoanAccount] = useQueryParam('loanAccount');
    const [duration, setDuration] = useQueryParam('duration');

    const onPopstate = useCallback(() => {
        const params = new URLSearchParams(window.location.search);
        setStage(params?.get('stage') ?? '0');
        setLoanAccount(undefined);
        setDuration(undefined);
    }, [setStage, setLoanAccount, setDuration]);

    useEffect(() => {
        window.addEventListener('popstate', onPopstate);
        return () => window.removeEventListener('popstate', onPopstate);
    }, [onPopstate]);

    return useMemo(() => {
        if (stage) {
            return { stage: Number.parseInt(stage, 10), loanAccount, duration };
        }
        return undefined;
    }, [stage, loanAccount, duration]);
};
export default useAvdragsParam;
