import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { RequestExceptionWithResponse } from '@eika-infrastruktur/request';

import { registrerInteresseskjema } from '../actions';
import { MeldInteresseFormFields } from '../components/melde-interesse-skjema-modal/melde-interesse-skjema-modal';
import { Bankregnr, ProcessArea, ProcessType } from '../generated-types';

type InteresseskjemaProps = {
    bankregnr: Bankregnr;
    produktkode: string;
    tags?: string;
};

const useInteresseskjema = ({
    bankregnr,
    produktkode,
    tags,
}: InteresseskjemaProps): UseMutationResult<void, RequestExceptionWithResponse, MeldInteresseFormFields, unknown> =>
    useMutation({
        mutationKey: ['inteeresseskjema'],
        mutationFn: ({
            fornavn,
            etternavn,
            telefonnummer,
            epost,
            fritekstfelt,
            adresse,
            postnummer,
        }: MeldInteresseFormFields) => {
            const adresseToSend = adresse && postnummer ? `${adresse}, ${postnummer}` : undefined;
            return registrerInteresseskjema({
                fornavn,
                etternavn,
                telefonnummer: { value: telefonnummer },
                epost: { value: epost },
                fritekstfelt,
                bankregnr,
                produktkode,
                prosesstype: ProcessType.SALG,
                prosessomrade: ProcessArea.DAGLIGBANK,
                tags,
                adresse: adresseToSend,
            });
        },
    });

export default useInteresseskjema;
