import React from 'react';

import { IconTypes } from '@eika/icon';

import AvdragsfrihetCard from '../components/avdragsfrihet-card';
import { useConfig } from '../default-config';
import useTranslation from '../hooks/useTranslation';

const AvdragsfrihetBanner: React.FC = () => {
    const { links } = useConfig();
    const translation = useTranslation();
    return (
        <AvdragsfrihetCard
            title={translation.avdragsfrihet.banner.title}
            cardIcon={IconTypes.RADGIVER_DYN}
            link={links.bannerLink.url}
        />
    );
};

export default AvdragsfrihetBanner;
