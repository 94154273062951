import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { ButtonWithSpinner } from '@eika/button-with-spinner';
import { CheckboxGroup } from '@eika/checkbox';
import { ExpandableInput } from '@eika/expandable-input';
import { Input, InputLabel } from '@eika/input';
import { ErrorLabel } from '@eika/label';
import { Link } from '@eika/link';

import { useConfig } from '../../default-config';
import useAcquaintanceForm from '../../hooks/useAcquaintanceForm';
import useDebounce from '../../hooks/useDebounce';
import useTranslation, { formatJsx } from '../../hooks/useTranslation';
import { acquaintanceFormTrackingID } from '../../utils/gtm/trackIDs/acquaintance';
import { BankUsage, bankUsage } from './bankUsage';
import useValidate from './validate/useValidate';

import './AcquaintanceForm.scss';

const AcquaintanceForm: React.FC = () => {
    const translated = useTranslation();
    const { bankregnr, links, numbers } = useConfig();
    const acquaintanceForm = useAcquaintanceForm();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [bankUsageList, setBankUsageList] = useState<BankUsage[]>([]);
    const [reasonForNewBank, setReasonForNewBank] = useState('');
    const [importantBankMessage, setImportantBankMessage] = useState('');

    const [errors, isFormValid, resetErrorForKey] = useValidate({
        name,
        email,
        phone,
        bankUsageList,
        reasonForNewBank,
        importantBankMessage,
    });

    const resetForm = useCallback(() => {
        resetErrorForKey('all');
        setName('');
        setEmail('');
        setPhone('');
        setBankUsageList([]);
        setReasonForNewBank('');
        setImportantBankMessage('');
        acquaintanceForm.reset();
    }, [
        acquaintanceForm,
        resetErrorForKey,
        setName,
        setEmail,
        setPhone,
        setBankUsageList,
        setReasonForNewBank,
        setImportantBankMessage,
    ]);

    const debouncedResetForm = useDebounce(resetForm, 3500);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }
        const nameSplit = name.split(' ');
        await acquaintanceForm.mutateAsync({
            bankregnr: { value: bankregnr },
            email,
            name: nameSplit[0] ?? '',
            phone,
            surname: nameSplit[nameSplit.length - 1] ?? '',
            reasonForNewBank,
            importantBankMessage,
            bankUsageList: bankUsageList.map((x) => translated.acquaintanceForm.bankUsages[x]),
        });
        debouncedResetForm();
    };

    const getCurrentBankUsageList = useCallback(
        (previousBankUsageList: BankUsage[], elementToAddOrRemove: BankUsage) => {
            const tempBankUsageArray = [...previousBankUsageList];
            if (tempBankUsageArray.includes(elementToAddOrRemove))
                return tempBankUsageArray.filter((x) => x !== elementToAddOrRemove);
            return [...tempBankUsageArray, elementToAddOrRemove];
        },
        [],
    );

    const toggleBankUsageItem = useCallback(
        (usage: BankUsage) => {
            setBankUsageList((prev) => getCurrentBankUsageList(prev, usage));
        },
        [setBankUsageList, getCurrentBankUsageList],
    );

    return (
        <form onSubmit={onSubmit} className="acquaintance-form__wrapper">
            <div className="acquaintance-form__intro-wrapper">
                <h2 className="acquaintance-form__intro--title">{translated.acquaintanceForm.keepYouUpToDate}</h2>
            </div>

            <section className={classNames('acquaintance-form__field-wrapper', 'acquaintance-form__info-wrapper')}>
                <h3 className="acquaintance-form__field-wrapper--title">{translated.acquaintanceForm.fillOutForm}</h3>
                <Input
                    className="acquaintance-form__field acquaintance-form__field--name"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        resetErrorForKey('name');
                    }}
                    label={translated.commonForm.name}
                    error={errors.name}
                />
                <Input
                    className="acquaintance-form__field acquaintance-form__field--email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        resetErrorForKey('email');
                    }}
                    label={translated.commonForm.email}
                    error={errors.email}
                />
                <Input
                    className="acquaintance-form__field acquaintance-form__field--phone"
                    value={phone}
                    onChange={(e) => {
                        setPhone(e.target.value);
                        resetErrorForKey('phone');
                    }}
                    label={translated.commonForm.phone}
                    error={errors.phone}
                />

                <InputLabel
                    label={translated.acquaintanceForm.speciallyImportantToYouAboutNewBank}
                    className="acquaintance-form__field acquaintance-form__field--bank-important"
                >
                    <ExpandableInput
                        value={importantBankMessage}
                        onChange={(e) => setImportantBankMessage(e.target.value)}
                        maxLength={numbers.freeTextMaxLength}
                    />
                </InputLabel>
                <InputLabel
                    label={translated.acquaintanceForm.reasonForNewBank}
                    className="acquaintance-form__field acquaintance-form__field--reason"
                >
                    <ExpandableInput
                        value={reasonForNewBank}
                        onChange={(e) => setReasonForNewBank(e.target.value)}
                        maxLength={numbers.freeTextMaxLength}
                    />
                </InputLabel>

                <section className="acquaintance-form__field acquaintance-form__field--bank-usage">
                    <CheckboxGroup
                        label={translated.acquaintanceForm.bankUsage}
                        options={bankUsage.map((usage) => ({
                            label: translated.acquaintanceForm.bankUsages[usage],
                            value: usage,
                            checked: bankUsageList.includes(usage),
                            onChange: () => toggleBankUsageItem(usage),
                        }))}
                    />
                    {errors.bankUsageList && (
                        <ErrorLabel
                            className="acquaintance-field__bank-usage-error-message"
                            text={errors.bankUsageList}
                        />
                    )}
                </section>
            </section>

            <section className="acquaintance-form__submit-wrapper">
                <p className="acquaintance-form__gdpr-description">
                    {formatJsx(
                        translated.acquaintanceForm.gdprMessage,
                        translated.acquaintanceForm.bankName,
                        <Link linkType="inline" href={links.gdprURL.url}>
                            {translated.acquaintanceForm.moreAboutGdprHere}
                        </Link>,
                    )}
                </p>
                <div className="acquaintance-form__submit-button--wrapper">
                    <ButtonWithSpinner
                        className="acquaintance-form__submit--button"
                        buttonType="positive"
                        type="submit"
                        showSpinner={acquaintanceForm.isPending}
                        showSuccess={acquaintanceForm.isSuccess}
                        data-track-id={acquaintanceFormTrackingID}
                        data-track-linkname="submit"
                    >
                        {translated.contactForm.sendForm}
                    </ButtonWithSpinner>
                    {acquaintanceForm.isSuccess && (
                        <span className="acquaintance-form__submit--success-message">
                            {translated.contactForm.successMessage}
                        </span>
                    )}
                </div>
            </section>
        </form>
    );
};
export default AcquaintanceForm;
