import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { ButtonWithSpinner } from '@eika/button-with-spinner';
import { ExpandableInput } from '@eika/expandable-input';
import { Input, InputLabel } from '@eika/input';
import { Link } from '@eika/link';

import { useConfig } from '../../default-config';
import { useContactMe } from '../../hooks/useContact';
import useDebounce from '../../hooks/useDebounce';
import useQueryParam from '../../hooks/useQueryParam';
import useTranslation, { formatJsx } from '../../hooks/useTranslation';
import { formatOrgNumber } from '../../utils/contact-form-utils';
import { contactFormTrackingIDs } from '../../utils/gtm/trackIDs/contact';
import { ContactFormType } from './types';
import useValidate from './validate/useValidate';

import './ContactMeForm.scss';

type Props = {
    type: ContactFormType;
};

const DEFAULT_PRODUCT = 'ukjent-produkt';

const ContactMeForm: React.FC<Props> = ({ type }) => {
    const translated = useTranslation();
    const { bankregnr, links, numbers } = useConfig();
    const contactMe = useContactMe();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [orgNr, setOrgNr] = useState('');
    const [ownText, setOwnText] = useState('');

    const [product] = useQueryParam('product');

    const [errors, isFormValid, resetErrorForKey] = useValidate(
        {
            name,
            email,
            phone,
            businessName,
            ownText,
        },
        type,
    );

    const resetForm = useCallback(() => {
        resetErrorForKey('all');
        setName('');
        setEmail('');
        setPhone('');
        setBusinessName('');
        setOrgNr('');
        setOwnText('');
        contactMe.reset();
    }, [contactMe, resetErrorForKey, setName, setEmail, setPhone, setBusinessName, setOrgNr, setOwnText]);

    const debouncedResetForm = useDebounce(resetForm, 3500);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }
        const nameSplit = name.split(' ');
        await contactMe.mutateAsync({
            bankregnr: { value: bankregnr },
            email,
            name: nameSplit[0] ?? '',
            phone,
            productName: product ?? DEFAULT_PRODUCT,
            surname: nameSplit[nameSplit.length - 1] ?? '',
            ownText,
            businessName,
            orgNr,
        });
        debouncedResetForm();
    };

    return (
        <form onSubmit={onSubmit} className="contact-me-form__wrapper">
            <div className="contact-me-form__intro-wrapper">
                <h2 className="contact-me-form__intro--title">{translated.contactForm.contactUs}</h2>
            </div>
            {type === 'business' && (
                <section className="contact-me-form__field-wrapper contact-me-form__business-info-wrapper">
                    <h3 className="contact-me-form__field-wrapper--title">{translated.contactForm.businessInfo}</h3>
                    <Input
                        className="contact-me-form__field"
                        value={businessName}
                        onChange={(e) => {
                            setBusinessName(e.target.value);
                            resetErrorForKey('businessName');
                        }}
                        label={translated.contactForm.businessName}
                        error={errors.businessName}
                    />
                    <Input
                        className="contact-me-form__field"
                        inputMode="numeric"
                        value={formatOrgNumber(orgNr)}
                        onChange={(e) => setOrgNr(e.target.value)}
                        label={translated.contactForm.orgNr}
                    />
                </section>
            )}
            <section
                className={classNames('contact-me-form__field-wrapper', 'contact-me-form__person-info-wrapper', {
                    'with-title': type === 'business',
                })}
            >
                {type === 'business' && (
                    <h3 className="contact-me-form__field-wrapper--title">{translated.contactForm.contactPerson}</h3>
                )}
                <Input
                    className="contact-me-form__field contact-me-form__field--name"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        resetErrorForKey('name');
                    }}
                    label={translated.commonForm.name}
                    error={errors.name}
                />
                <Input
                    className="contact-me-form__field contact-me-form__field--phone"
                    value={phone}
                    onChange={(e) => {
                        setPhone(e.target.value);
                        resetErrorForKey('phone');
                    }}
                    label={translated.commonForm.phone}
                    error={errors.phone}
                />
                <Input
                    className="contact-me-form__field contact-me-form__field--email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        resetErrorForKey('email');
                    }}
                    label={translated.commonForm.email}
                    error={errors.email}
                />
                <InputLabel
                    label={translated.contactForm.purposeOfContact}
                    className="contact-me-form__field_wrapper--purpose"
                >
                    <ExpandableInput
                        className="contact-me-form__field--purpose"
                        value={ownText}
                        onChange={(e) => setOwnText(e.target.value)}
                        maxLength={numbers.freeTextMaxLength}
                    />
                    <p className="contact-me-form__field_purpose--description">
                        {translated.contactForm.purposeOfContactInfoMessage}
                    </p>
                </InputLabel>
            </section>

            <section className="contact-me-form__submit-wrapper">
                <p className="contact-me-form__gdpr-description">
                    {formatJsx(
                        translated.contactForm.contactDeletionRoutines,
                        <Link linkType="inline" href={links.gdprURL.url}>
                            {translated.contactForm.contactDeletionGDPRLinkText}
                        </Link>,
                    )}
                </p>
                <div className="contact-me-form__submit-button--wrapper">
                    <ButtonWithSpinner
                        className="contact-me-form__submit--button"
                        buttonType="positive"
                        type="submit"
                        showSpinner={contactMe.isPending}
                        showSuccess={contactMe.isSuccess}
                        data-track-id={contactFormTrackingIDs[type].id}
                        data-track-linkname="submit"
                    >
                        {translated.contactForm.sendForm}
                    </ButtonWithSpinner>
                    {contactMe.isSuccess && (
                        <span className="contact-me-form__submit--success-message">
                            {translated.contactForm.successMessage}
                        </span>
                    )}
                </div>
            </section>
        </form>
    );
};
export default ContactMeForm;
