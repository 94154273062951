export type BankUsage =
    | 'BILLS'
    | 'MOBILE-PAY'
    | 'SAVINGS-ACCOUNT'
    | 'HOUSE-LOAN'
    | 'CAR-LOAN'
    | 'OTHER-LOANS'
    | 'CREDIT-CARD'
    | 'SHARES-AND-FUNDS'
    | 'ADVISOR'
    | 'INSURANCE'
    | 'FAMILY-EXPENSES'
    | 'FAMILY-ACCOUNT-OVERVIEW';

export const bankUsage: BankUsage[] = [
    'BILLS',
    'MOBILE-PAY',
    'SAVINGS-ACCOUNT',
    'HOUSE-LOAN',
    'CAR-LOAN',
    'OTHER-LOANS',
    'CREDIT-CARD',
    'SHARES-AND-FUNDS',
    'ADVISOR',
    'INSURANCE',
    'FAMILY-EXPENSES',
    'FAMILY-ACCOUNT-OVERVIEW',
];
