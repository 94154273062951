import React, { Fragment } from 'react';

import { useConfig } from '../default-config';
import TranslationsConfigType from '../translations/translationType';

export const format = (value: string, ...args: (string | number)[]): string => {
    let i = 0;
    return value.replace(/{}/g, (): string => {
        // eslint-disable-next-line no-plusplus
        return typeof args[i] !== 'undefined' ? args[i++].toString() : '';
    });
};

export const formatJsx = (value: string, ...args: React.ReactNode[]): React.ReactNode[] | string => {
    return value.split('{}').reduce((l, p, i) => {
        const arg = args[i - 1];
        if (i > 0 && typeof arg !== 'undefined') {
            if (typeof arg === 'object') {
                // eslint-disable-next-line react/no-array-index-key
                l.push(<Fragment key={i}>{arg}</Fragment>);
            } else {
                l.push(arg);
            }
        }
        l.push(p);
        return l;
    }, [] as React.ReactNode[]);
};

const useTranslation = (): TranslationsConfigType => {
    const { siteLang, translations } = useConfig();
    return translations[siteLang];
};

export default useTranslation;
