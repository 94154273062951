import React, { useEffect, useRef } from 'react';

import { CheckmarkAnimation } from '@eika/checkmark-animation';
import { Link, LinkType } from '@eika/link';

import './success-card.scss';

interface SuccessCardProps {
    text: string;
    title: string;
    link: {
        to: string;
        text: string;
    };
}

const SuccessCard: React.FC<SuccessCardProps> = ({ text, title, link }) => {
    const titleRef = useRef<HTMLHeadingElement>(null);
    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.tabIndex = -1;
            titleRef.current.focus();
        }
    }, [titleRef]);
    return (
        <div className="success-card__wrapper">
            <div className="success-card">
                <CheckmarkAnimation key={1} className="success-card__checkmark" />
                <h1 className="success-card__title" ref={titleRef}>
                    {title}
                </h1>
                <p className="success-card__description">{text}</p>
                <Link linkType={LinkType.BUTTON_STANDARD_POSITIVE} className="success-card__link" href={link.to}>
                    {link.text}
                </Link>
            </div>
        </div>
    );
};

export default SuccessCard;
