export enum ErrorTypes {
    CUSTOMER_CONTACT_FORM = 'Send contact form',
}

export type errorEvent = {
    errorName: string;
    errorDescription: string;
    errorCode: string;
    appNamespace: string;
};
