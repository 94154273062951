import React from 'react';

import { RequestExceptionWithResponse } from '@eika-infrastruktur/request';
import { ErrorCard } from '@eika/card';
import { Icon, IconTypes } from '@eika/icon';

import useTranslation from '../../hooks/useTranslation';
import { ErrorsConfigType } from '../../translations/translationType';

export enum ErrorCodes {
    TIMEOUT = 'DAG2820005',
    INTERNAL_ERROR = 'DAG2820001',
}

interface ErrorCardProps {
    title: string;
    children?: React.ReactNode | React.ReactNode[];
    exception: RequestExceptionWithResponse;
}

const defaultTitle = (isTimeout: boolean, errors: ErrorsConfigType) =>
    isTimeout ? errors.ohNo : errors.somethingWentWrong;

const defaultRender = (isTimeout: boolean, errors: ErrorsConfigType) =>
    isTimeout ? <p className="timeout">{errors.somethingWentWrong}</p> : <p>{errors.defaultMessage}</p>;

const ErrorCardWrapper = ({ title, exception, children }: ErrorCardProps): JSX.Element => {
    const translated = useTranslation();
    const isTimeout = exception.code === ErrorCodes.TIMEOUT;
    const errorTitle = title || defaultTitle(isTimeout, translated.errors);
    const errorDescription = defaultRender(isTimeout, translated.errors);
    return (
        <ErrorCard
            exception={exception}
            title={errorTitle}
            description={errorDescription}
            icon={
                isTimeout ? (
                    <Icon type={IconTypes.BANDAGED_HEART} />
                ) : (
                    <Icon className="error" type={IconTypes.UTROPSTEGN_36} />
                )
            }
        >
            {children}
        </ErrorCard>
    );
};
export default ErrorCardWrapper;
