import React, { useEffect, useState, useCallback } from 'react';

import { RequestExceptionWithResponse } from '@eika-infrastruktur/request';
import { ButtonWithSpinner } from '@eika/button-with-spinner';

import { useConfig } from '../../default-config';
import { AvdragsfrihetParams } from '../../generated-types';
import useAvdragsParam from '../../hooks/useAvdragsParam';
import { useAvdragsfrihet } from '../../hooks/useContact';
import useLoanAccounts from '../../hooks/useLoans';
import useTranslation from '../../hooks/useTranslation';
import getArrayFromObject from '../../utils/array-utils';
import { ErrorCardWrapper } from '../error-card-wrapper';
import SuccessCard from '../success-card';
import AvdragsfrihetFormSkeleton from './avdragsfrihet-form-skeleton';
import DurationPicker from './duration-picker';
import Intro from './intro';
import LoanPicker from './loan-picker';
import NoLoans from './no-loans';
import ReasonPicker from './reason-picker';

import './avdragsfrihet-form.scss';

export enum AvdragsfrihetStage {
    START = 0,
    LOAN = 1,
    DURATION = 2,
    REASON = 3,
    SUCCESS = 4,
}

const AvdragsfrihetForm: React.FC = () => {
    const { links, subjects } = useConfig();
    const avdragsParam = useAvdragsParam();

    const {
        data: loanAccountList,
        error: loanAccountListError,
        isLoading: loanAccountListIsLoading,
        refetch: refreshLoanAccountList,
    } = useLoanAccounts();

    const [stage, setStage] = useState(AvdragsfrihetStage.START);
    const [duration, setDuration] = useState<string>();
    const [reason, setReason] = useState<string>();
    const [loanAccount, setLoanAccount] = useState<string>();
    const [reasonExplained, setReasonExplained] = useState<string>();

    const translation = useTranslation();

    useEffect(() => {
        setStage(avdragsParam?.stage ?? 0);
        setDuration(avdragsParam?.duration);
        setLoanAccount(avdragsParam?.loanAccount);
    }, [avdragsParam]);

    const determineStage = useCallback(
        (value: AvdragsfrihetStage) => {
            setStage(value);
            window.history.pushState(
                { stage: value, loanAccount, duration },
                '',
                `?${new URLSearchParams({
                    stage: value.toString(),
                    loanAccount: loanAccount ?? '',
                    duration: duration ?? '',
                }).toString()}`,
            );
        },
        [setStage, loanAccount, duration],
    );
    const successCallback = useCallback(() => determineStage(AvdragsfrihetStage.SUCCESS), [determineStage]);
    const avdragsfrihet = useAvdragsfrihet(successCallback);

    const validateBeforeSubmit = () => {
        if (!reason) return false;
        return duration;
    };

    const submit = async () => {
        const valid = validateBeforeSubmit();
        if (valid && reason && duration) {
            const currentLoanAccount = loanAccountList?.find((loan) => loan.accountNumber.value === loanAccount);
            const durationText = getArrayFromObject(subjects).find((x) => x.value === duration)?.text ?? duration;
            const loanAccountText = currentLoanAccount
                ? `${currentLoanAccount.alias} (kontonr: ${currentLoanAccount.accountNumber.value})`
                : undefined;
            const avdragsfrihetParam = {
                aarsak: reason,
                laan: loanAccountText,
                varighet: durationText,
                tekst: reason === 'Annet' ? reasonExplained?.trim() : '',
            } as AvdragsfrihetParams;
            avdragsfrihet.mutate(avdragsfrihetParam);
        }
    };

    const reset = () => {
        setStage(AvdragsfrihetStage.START);
        setDuration(undefined);
        setReason(undefined);
        setReasonExplained(undefined);
    };

    const retry = (withNavigation = true) => {
        reset();
        if (withNavigation) {
            determineStage(AvdragsfrihetStage.START);
        }
        refreshLoanAccountList();
    };

    const exception = avdragsfrihet.error;
    if (exception) {
        return (
            <div>
                <ErrorCardWrapper
                    title={translation.errors.somethingWentWrong}
                    exception={exception as RequestExceptionWithResponse}
                >
                    <ButtonWithSpinner
                        buttonType="positive"
                        showSpinner={loanAccountListIsLoading}
                        onClick={() => retry()}
                    >
                        {translation.tryAgain}
                    </ButtonWithSpinner>
                </ErrorCardWrapper>
            </div>
        );
    }

    if (loanAccountListIsLoading) {
        return <AvdragsfrihetFormSkeleton />;
    }

    const loanAccounts = loanAccountList ?? [];
    const hasLoans = loanAccounts.length > 0;

    return (
        <form className="avdragsfrihet-form">
            {stage === AvdragsfrihetStage.START && <Intro start={() => determineStage(AvdragsfrihetStage.LOAN)} />}
            {stage !== AvdragsfrihetStage.START && !hasLoans && (
                <NoLoans loanLink={links.loanLink.url} prev={() => determineStage(AvdragsfrihetStage.START)} />
            )}
            {hasLoans && (
                <>
                    {stage === AvdragsfrihetStage.LOAN && (
                        <LoanPicker
                            request={{ retry: () => retry(false), error: loanAccountListError }}
                            loans={loanAccounts}
                            selectedAccount={loanAccount}
                            setSelectedAccount={setLoanAccount}
                            prev={() => determineStage(AvdragsfrihetStage.START)}
                            next={() => determineStage(AvdragsfrihetStage.DURATION)}
                        />
                    )}
                    {stage === AvdragsfrihetStage.DURATION && (
                        <DurationPicker
                            duration={duration}
                            setDuration={(newDuration: string | undefined) => setDuration(newDuration)}
                            prev={() => determineStage(AvdragsfrihetStage.LOAN)}
                            next={() => determineStage(AvdragsfrihetStage.REASON)}
                        />
                    )}
                    {stage === AvdragsfrihetStage.REASON && (
                        <ReasonPicker
                            reason={reason}
                            setReason={(newReason: string | undefined) => setReason(newReason)}
                            reasonExplained={reasonExplained}
                            setReasonExplained={(newReasonExplained: string | undefined) =>
                                setReasonExplained(newReasonExplained)
                            }
                            prev={() => determineStage(AvdragsfrihetStage.DURATION)}
                            submit={() => submit()}
                            loading={avdragsfrihet.isPending}
                        />
                    )}
                    {stage === AvdragsfrihetStage.SUCCESS && (
                        <SuccessCard
                            title={translation.avdragsfrihet.form.successTitle}
                            text={translation.avdragsfrihet.form.successDescription}
                            link={{ to: links.loanLink.url, text: translation.buttonTextOk }}
                        />
                    )}
                </>
            )}
        </form>
    );
};

export default AvdragsfrihetForm;
