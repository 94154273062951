import { useCallback, useState } from 'react';

import useTranslation from '../../../hooks/useTranslation';
import { isValidEmail, validateName, isValidPhoneNumber } from '../../../utils/validateContact';
import { ErrorTypes, FormValues } from '../types';

const NAME_MAX_LENGTH = 50;

const initialErrorState: ErrorTypes = {
    name: undefined,
    bankUsageList: undefined,
    email: undefined,
    phone: undefined,
};

const useValidate = (formValues: FormValues): [ErrorTypes, () => boolean, (key: keyof ErrorTypes | 'all') => void] => {
    const translated = useTranslation();
    const [errors, setErrors] = useState<ErrorTypes>(initialErrorState);

    const resetErrorForKey = useCallback(
        (key: keyof ErrorTypes | 'all') => {
            if (key === 'all') {
                setErrors(initialErrorState);
                return;
            }
            const errorsToSet = errors;

            if (!errorsToSet[key]) return;
            errorsToSet[key] = undefined;
            setErrors(errorsToSet);
        },
        [errors, setErrors],
    );

    const validateCallback = useCallback((): boolean => {
        const errorsToSet = {
            ...initialErrorState,
            name: validateName(translated)(formValues.name, NAME_MAX_LENGTH),
        };

        if (!isValidEmail(formValues.email)) {
            errorsToSet.email = translated.commonForm.errors.validation.email;
        }
        if (!isValidPhoneNumber(formValues.phone)) {
            errorsToSet.phone = translated.commonForm.errors.validation.phone;
        }
        if (formValues.bankUsageList.length === 0) {
            errorsToSet.bankUsageList = translated.acquaintanceForm.errors.validation.bankUsageList;
        }
        setErrors({ ...errorsToSet });
        return !Object.entries(errorsToSet).some(([, value]) => value);
    }, [formValues, translated, setErrors]);

    return [errors, validateCallback, resetErrorForKey];
};

export default useValidate;
