import React, { useRef, useEffect } from 'react';

import { Button } from '@eika/button';
import { Link } from '@eika/link';

import { useConfig } from '../../default-config';
import useTranslation from '../../hooks/useTranslation';

import familieBilde from '../../resources/familiebilde.svg';
import './intro.scss';

interface IntroProps {
    start: () => void;
}

const Intro: React.FC<IntroProps> = ({ start }) => {
    const translation = useTranslation();
    const { featureToggles, links } = useConfig();

    const titleRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.tabIndex = -1;
            titleRef.current.focus();
        }
    }, [titleRef]);

    return (
        <div className="avdragsfrihet-form__content avdragsfrihet-form__intro">
            <div className="avdragsfrihet-form__intro-img-wrapper">
                <img className="avdragsfrihet-form__intro-img" src={familieBilde} alt="" />
            </div>
            <div className="avdragsfrihet-form__intro-text-wrapper">
                <h2 className="avdragsfrihet-form__intro-title" ref={titleRef}>
                    {translation.avdragsfrihet.title}
                </h2>
                <p className="avdragsfrihet-form__intro-description">{translation.avdragsfrihet.description}</p>
                {featureToggles.showFeeWarning && links.priceListLink && (
                    <>
                        <p className="avdragsfrihet-form__intro-fee-warning">{translation.avdragsfrihet.feeWarning}</p>
                        <div className="avdragsfrihet-form__intro-link-container">
                            <Link href={links.priceListLink.url}>{translation.avdragsfrihet.feeWarningLinkText}</Link>
                        </div>
                    </>
                )}
            </div>
            <div className="avdragsfrihet-form__button-group avdragsfrihet-form__item-centered">
                <Button onClick={start}>{translation.avdragsfrihet.form.start}</Button>
            </div>
        </div>
    );
};

export default Intro;
