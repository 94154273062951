import React from 'react';

import { IconTypes } from '@eika/icon';
import { LinkCard } from '@eika/link-panel';

interface AvdragsfrihetCardProps {
    title: string;
    description?: string;
    cardIcon: IconTypes;
    link: string;
}

const AvdragsfrihetCard: React.FC<AvdragsfrihetCardProps> = ({ title, cardIcon, link, description }) => {
    return <LinkCard title={title} url={link} icon={cardIcon} textOne={description} />;
};

export default AvdragsfrihetCard;
