import React, { useState } from 'react';

import { ButtonWithSpinner } from '@eika/button-with-spinner';

import { ErrorCardWrapper } from '../components/error-card-wrapper';
import SuccessCard from '../components/success-card';
import { useConfig } from '../default-config';
import { useContact } from '../hooks/useContact';
import useTranslation, { format } from '../hooks/useTranslation';

import '../components/avdragsfrihet-form/avdragsfrihet-form.scss';
import '../components/avdragsfrihet-form/intro.scss';
import bedriftBilde from '../resources/bedrift.svg';
import familieBilde from '../resources/familiebilde.svg';

const AdvisorContactForm: React.FC = () => {
    const translation = useTranslation();
    const { subjects, links, numberOfDays } = useConfig();
    const subject = Object.keys(subjects)[0] ?? 'avdragsfrihet';

    const [showPrompt, setShowPrompt] = useState(false);

    const getText = (subject: string) => {
        switch (subject.toLowerCase()) {
            case 'avdragsfrihet':
                return {
                    title: translation.avdragsfrihet.title,
                    description: translation.avdragsfrihet.description,
                    prompt: {
                        title: translation.avdragsfrihet.banner.title,
                        description: translation.avdragsfrihet.promptDescription,
                    },
                };
            case 'bedrift':
                return {
                    title: translation.bedrift.title,
                    description: translation.bedrift.description,
                };
            default:
                return {
                    title: translation.contact.title,
                    description: translation.contact.description,
                };
        }
    };

    const contact = useContact();
    const submit = () => {
        const usePrompt = subject.toLowerCase() === 'avdragsfrihet';
        if (usePrompt && !showPrompt) {
            setShowPrompt(true);
            return;
        }
        contact.mutate({
            tema: subject,
            tekst: subject.toLowerCase() === 'bedrift' ? 'Bedriftsmobilbank' : '',
            appLokasjon: window.location.pathname,
        });
    };

    const texts = getText(subject);

    return (
        <form className="avdragsfrihet-form">
            {!contact.isSuccess ? (
                <div className="avdragsfrihet-form__content avdragsfrihet-form__intro">
                    <div className="avdragsfrihet-form__intro-img-wrapper">
                        <img
                            className="avdragsfrihet-form__intro-img"
                            src={subject.toLowerCase() === 'bedrift' ? bedriftBilde : familieBilde}
                            alt=""
                        />
                    </div>
                    {showPrompt && texts.prompt?.description && texts.prompt?.title ? (
                        <div className="avdragsfrihet-form__intro-text-wrapper">
                            <h2 className="avdragsfrihet-form__intro-title">{texts.prompt.title}</h2>
                            <p className="avdragsfrihet-form__intro-description">{texts.prompt.description}</p>
                        </div>
                    ) : (
                        <div className="avdragsfrihet-form__intro-text-wrapper">
                            <h2 className="avdragsfrihet-form__intro-title">{texts.title}</h2>
                            <p className="avdragsfrihet-form__intro-description">{texts.description}</p>
                        </div>
                    )}
                    <div className="avdragsfrihet-form__button-group avdragsfrihet-form__item-centered">
                        {contact.isError ? (
                            <ErrorCardWrapper title={translation.errors.somethingWentWrong} exception={contact.error}>
                                <ButtonWithSpinner
                                    showSpinner={contact.isPending}
                                    showSuccess={contact.isSuccess}
                                    buttonType="positive"
                                    onClick={submit}
                                >
                                    {translation.tryAgain}
                                </ButtonWithSpinner>
                            </ErrorCardWrapper>
                        ) : (
                            <ButtonWithSpinner showSpinner={contact.isPending} buttonType="positive" onClick={submit}>
                                {showPrompt ? translation.contact.buttonText : translation.createRequest}
                            </ButtonWithSpinner>
                        )}
                    </div>
                </div>
            ) : (
                <SuccessCard
                    title={translation.contact.requestSentTitle}
                    text={format(translation.contact.requestSentDescription, numberOfDays)}
                    link={{ to: links.loanLink.url, text: translation.buttonTextOk }}
                />
            )}
        </form>
    );
};

export default AdvisorContactForm;
