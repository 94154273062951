import { useEffect, useState } from 'react';

function useDebounce<TReturn = void, TArguments = void>(func: (args: TArguments) => TReturn, delay = 500) {
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    useEffect(() => {
        return () => clearTimeout(timer);
    }, [timer]);

    return (args: TArguments) => {
        const newTimer = setTimeout(() => {
            func(args);
        }, delay);
        clearTimeout(timer);
        setTimer(newTimer);
    };
}

export default useDebounce;
