export const isValidPhoneNumber = (phone: string): boolean => {
    return /^\(?(\+\d\d?[ .]*)?\)?([\d ()]+)$/.test(phone);
};

export const isValidEmail = (email: string): boolean => {
    return /^[^@]+@[^@]+\.[a-zA-Z]+$/.test(email);
};

export const isValidFullName = (name: string): boolean => {
    return name.trim().includes(' ');
};

export const getNames = (fullName: string): [string, string] => {
    const trimmedValue = fullName.trim().replace(/ +(?= )/g, '');
    if (trimmedValue.length === 0) return [trimmedValue, ''];
    const split = trimmedValue.split(' ');
    if (split.length === 1) return [trimmedValue, ''];
    const surname = split.pop();
    return [split.join(' '), surname ?? ''];
};

export const isValidQueryParam = (param: string): boolean => {
    return /^[0-9a-zæøåA-ZÆØÅ-]+$/.test(param);
};

export const getBankregnr = (bankregnr?: string, company?: string): string | undefined => {
    if (bankregnr !== '0770') return bankregnr;
    switch (company) {
        case 'ekf':
            return '0501';
        case 'ekb':
            return '9811';
        case 'efp':
            return '0771';
        default:
            return '0770';
    }
};

export const formatOrgNumber = (value: string | undefined, seperator = ' ') => {
    if (!value) return '';
    const withoutAlphabet = value.replace(/([a-zA-Z])/g, '');
    const onlyNums = value.replace(/[^\d]/g, '');
    if (withoutAlphabet !== value || onlyNums.length !== 9) {
        if (onlyNums.length === 11) {
            return [onlyNums.slice(0, 4), onlyNums.slice(4, 7), onlyNums.slice(7, 11)].join(seperator);
        }
        return value;
    }
    return [onlyNums.slice(0, 3), onlyNums.slice(3, 6), onlyNums.slice(6, 9)].join(seperator);
};
