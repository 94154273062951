import React from 'react';
import Skeleton from 'react-loading-skeleton';

const AvdragsfrihetFormSkeleton: React.FC = () => {
    return (
        <div className="avdragsfrihet-form">
            <div className="avdragsfrihet-form__content avdragsfrihet-form__intro">
                <Skeleton width={280} height={180} className="avdragsfrihet-form__intro-img-wrapper" />
                <Skeleton width={340} height={30} className="avdragsfrihet-form__intro-title" />
                <Skeleton width={340} height={200} className="avdragsfrihet-form__intro-description" />
            </div>
        </div>
    );
};

export default AvdragsfrihetFormSkeleton;
