import React, { useRef, useEffect } from 'react';

import { Button } from '@eika/button';
import { Link } from '@eika/link';

import useTranslation from '../../hooks/useTranslation';

import empatisk from '../../resources/empatisk.svg';
import './intro.scss';

interface NoLoansProps {
    loanLink: string;
    prev: () => void;
}

const NoLoans: React.FC<NoLoansProps> = ({ loanLink, prev }) => {
    const translation = useTranslation();

    const titleRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.tabIndex = -1;
            titleRef.current.focus();
        }
    }, [titleRef]);

    return (
        <div className="avdragsfrihet-form__content avdragsfrihet-form__intro">
            <div className="avdragsfrihet-form__intro-img-wrapper">
                <img className="avdragsfrihet-form__intro-img" src={empatisk} alt="" />
            </div>
            <div className="avdragsfrihet-form__intro-text-wrapper">
                <h2 className="avdragsfrihet-form__intro-title" ref={titleRef}>
                    {translation.avdragsfrihet.noLoansHeader}
                </h2>
                <p className="avdragsfrihet-form__intro-description">{translation.avdragsfrihet.description}</p>
            </div>
            <div className="avdragsfrihet-form__button-group avdragsfrihet-form__item-centered">
                <Button buttonType="negative" onClick={prev} type="button">
                    {translation.avdragsfrihet.form.previous}
                </Button>
                <Link linkType="button-positive" href={loanLink}>
                    {translation.avdragsfrihet.noLoansRedirect}
                </Link>
            </div>
        </div>
    );
};

export default NoLoans;
