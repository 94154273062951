import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { RequestExceptionWithResponse } from '@eika-infrastruktur/request';

import { getLoanAccounts } from '../actions';
import { LoanDetails } from '../generated-types';

const useLoanAccounts = (): UseQueryResult<LoanDetails[], RequestExceptionWithResponse> =>
    useQuery({ queryKey: ['loanAccounts'], queryFn: getLoanAccounts });

export default useLoanAccounts;
