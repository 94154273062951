import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AttentionMessage, AttentionMessageTypes } from '@eika/attention-message';
import { Button } from '@eika/button';
import { ButtonWithSpinner } from '@eika/button-with-spinner';
import { ExpandableInput } from '@eika/expandable-input';
import { IconTypes } from '@eika/icon';
import { InputLabel } from '@eika/input';
import { ErrorLabel } from '@eika/label';
import { RadioButtons } from '@eika/radio-button';

import { useConfig } from '../../default-config';
import useTranslation from '../../hooks/useTranslation';

interface ReasonPickerProps {
    reason: string | undefined;
    setReason: (newReason: string | undefined) => void;
    reasonExplained: string | undefined;
    setReasonExplained: (newReason: string | undefined) => void;
    prev: () => void;
    submit: () => void;
    loading: boolean;
}

const defaultMaxLength = 50;

const ReasonPicker: React.FC<ReasonPickerProps> = ({
    reason,
    setReason,
    reasonExplained,
    setReasonExplained,
    prev,
    submit,
    loading,
}) => {
    const translation = useTranslation();
    const { avdragsfrihetSubjects, numbers } = useConfig();
    const reasonArray = Object.entries(avdragsfrihetSubjects).map((arr) => ({
        label: arr[1],
        value: arr[1],
        key: arr[0],
    }));

    const maxLength = numbers?.textAreaTextLength ?? defaultMaxLength;

    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const validateReasonExplained = useCallback(
        (value: string) => {
            const tempValue = value.trim();
            if (tempValue.length >= maxLength) {
                return translation.avdragsfrihet.form.reasonExplainedErrorTooLong.replace('{}', maxLength.toString());
            }

            if (tempValue.length < 3) {
                return translation.avdragsfrihet.form.reasonExplainedErrorTooShort;
            }

            return '';
        },
        [translation, maxLength],
    );

    const onChange = (value: string) => {
        setReason(value);
        setErrorMessage(undefined);
    };

    const onReasonExplainedChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setReasonExplained(value);
        if (value.trim().length < maxLength) {
            setErrorMessage(undefined);
        } else {
            setErrorMessage(validateReasonExplained(value));
        }
    };

    const onSubmit = () => {
        const errMessage = validateReasonExplained(reasonExplained ?? '');
        if (reason === 'Annet' && errMessage.length > 0) {
            setErrorMessage(errMessage);
            return;
        }
        if (reason === undefined) {
            setErrorMessage(translation.avdragsfrihet.form.chooseReasonError);
            return;
        }
        submit();
    };

    const radioButtonsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (radioButtonsRef.current) {
            const el = radioButtonsRef.current.querySelector('.sgw-radio-button-legend') as HTMLSpanElement;
            el?.setAttribute('tabindex', '-1');
            el?.focus();
        }
    }, [radioButtonsRef]);

    return (
        <section className="avdragsfrihet-form__content" ref={radioButtonsRef}>
            <RadioButtons
                label={translation.avdragsfrihet.form.reasonTitle}
                options={reasonArray}
                selectedOption={reason}
                onChange={onChange}
                errorMessage={reason !== 'Annet' ? errorMessage : undefined}
            />

            {reason === 'Annet' && (
                <div className="reason-explained__input-wrapper">
                    <InputLabel label={translation.avdragsfrihet.form.reasonExplainedLabel}>
                        <ExpandableInput
                            value={reasonExplained}
                            onChange={onReasonExplainedChange}
                            onBlur={() => setErrorMessage(validateReasonExplained(reasonExplained ?? ''))}
                            aria-describedby="reason-explained-error"
                            error={
                                errorMessage && (
                                    <ErrorLabel id="reason-explained-error" aria-live="polite" text={errorMessage} />
                                )
                            }
                            maxLength={maxLength}
                        />
                    </InputLabel>
                </div>
            )}

            <div className="avdragsfrihet-form__button-group no-margin-auto avdragsfrihet-form__item-centered">
                <Button buttonType="negative" onClick={prev}>
                    {translation.avdragsfrihet.form.previous}
                </Button>
                <ButtonWithSpinner showSpinner={loading} buttonType="positive" onClick={onSubmit}>
                    {translation.send}
                </ButtonWithSpinner>
            </div>
            <AttentionMessage
                className="avdragsfrihet-form__credit-message"
                icon={IconTypes.INFO_24}
                type={AttentionMessageTypes.INFO}
                text={translation.avdragsfrihet.form.creditCheck}
            />
        </section>
    );
};

export default ReasonPicker;
