import EmailValidator from 'email-validator';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { format8DigitPhoneNumber } from '@eika-forsikring/formats';
import { STATUS_BAD_REQUEST } from '@eika-infrastruktur/request';
import { ErrorMessage } from '@eika/attention-message';
import { Button, ButtonTypes } from '@eika/button';
import { ButtonWithSpinner } from '@eika/button-with-spinner';
import { Input } from '@eika/input';
import { Modal } from '@eika/modal';

import { useConfig } from '../../default-config';
import useInteresseskjema from '../../hooks/useInteresseskjema';
import useMeldInteresseSitecoreTranslation from '../../hooks/useMeldInteresseSitecoreTranslation';
import useTranslation, { format } from '../../hooks/useTranslation';
import MeldeInteresseCheckboxAndFritekstfelt from './melde-interesse-checkbox-and-fritekstfelt';
import Footer from './melde-interesse-footer';

import './melde-interesse-skjema-modal.scss';

interface MeldeInteresseSkjemaModalProps {
    open: boolean;
    setOpen: React.Dispatch<boolean>;
}

export interface MeldInteresseFormFields {
    fornavn: string;
    etternavn: string;
    telefonnummer: string;
    epost: string;
    fritekstfelt?: string;
    adresse?: string;
    postnummer?: string;
}

export const GARD_UTEN_DRIFT_PRODUKTKODE = 'gard-uten-drift';

const MeldeInteresseSkjemaModal = ({ open, setOpen }: MeldeInteresseSkjemaModalProps) => {
    const translations = useTranslation();
    const sitecoreTranslations = useMeldInteresseSitecoreTranslation();
    const { meldinteresseconfig, links, bankregnr } = useConfig();

    const [maybeResetMutationAndForm, setMaybeResetMutationAndForm] = useState(false);

    const isGardUtenDrift = meldinteresseconfig.produktkode === GARD_UTEN_DRIFT_PRODUKTKODE;

    const {
        handleSubmit,
        register,
        control,
        reset: resetForm,
        getValues,
        formState: { errors },
    } = useForm<MeldInteresseFormFields>({
        mode: 'onBlur',
    });

    const {
        mutate,
        isPending: interesseskjemaIsSubmitting,
        isSuccess: interesseskjemaHasBeenSubmitted,
        error: interesseskjemaError,
        reset: resetMutation,
    } = useInteresseskjema({
        bankregnr: {
            value: bankregnr,
        },
        produktkode: meldinteresseconfig.produktkode,
        tags: meldinteresseconfig.tags,
    });

    const resetAndCloseModal = () => {
        setOpen(false);
        setMaybeResetMutationAndForm(true);
    };

    const onSubmit = (data: MeldInteresseFormFields) => {
        setMaybeResetMutationAndForm(false);
        mutate(data);
    };

    useEffect(() => {
        if (maybeResetMutationAndForm && open) {
            resetMutation();
            if (interesseskjemaHasBeenSubmitted) {
                resetForm();
            }
        }
    }, [maybeResetMutationAndForm, open, resetMutation, interesseskjemaHasBeenSubmitted, resetForm]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            onClose={resetAndCloseModal}
            size="large"
            title={interesseskjemaHasBeenSubmitted ? '' : sitecoreTranslations.title}
            footerClassName="melde-interesse-skjema-modal__footer"
            footerContent={<Footer />}
            shouldCloseOnOverlayClick
            dataTrackName={`forsikring-melde-interesse-skjema-${meldinteresseconfig.produktkode}`}
            appNamespace="dagligbank-kontakt-meg-web"
        >
            {interesseskjemaHasBeenSubmitted ? (
                <div className="melde-interesse-skjema-confirmation">
                    <div className="melde-interesse-skjema-modal-confirmation__text-group">
                        <h2>
                            {format(
                                translations.meldInteresse.confirmation.headerWithCustomerFirstName,
                                getValues('fornavn'),
                            )}
                        </h2>
                        <p>
                            {format(
                                translations.meldInteresse.confirmation.informationWithCustomerPhoneNumber,
                                format8DigitPhoneNumber(getValues('telefonnummer')),
                            )}
                        </p>
                    </div>
                    <Button onClick={resetAndCloseModal}>{translations.meldInteresse.confirmation.button}</Button>
                </div>
            ) : (
                <div className="melde-interesse-skjema-form">
                    <p>{sitecoreTranslations.description}</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="melde-interesse-skjema-input-row">
                            <Input
                                className="melde-interesse-skjema-input-row__input-field"
                                label={translations.commonForm.firstName}
                                {...register('fornavn', {
                                    required: translations.commonForm.errors.required.firstName,
                                })}
                                error={errors.fornavn?.message}
                            />
                            <Input
                                className="melde-interesse-skjema-input-row__input-field"
                                label={translations.commonForm.surname}
                                {...register('etternavn', {
                                    required: translations.commonForm.errors.required.surname,
                                })}
                                error={errors.etternavn?.message}
                            />
                        </div>
                        <div className="melde-interesse-skjema-input-row">
                            <Input
                                className="melde-interesse-skjema-input-row__input-field"
                                label={translations.commonForm.phone}
                                {...register('telefonnummer', {
                                    required: translations.commonForm.errors.required.phone,
                                    pattern: {
                                        value: /^\d{8}$/,
                                        message: translations.commonForm.errors.validation.phone,
                                    },
                                })}
                                error={errors.telefonnummer?.message}
                            />
                            <Input
                                className="melde-interesse-skjema-input-row__input-field"
                                label={translations.commonForm.email}
                                {...register('epost', {
                                    required: translations.commonForm.errors.required.email,
                                    validate: (value) =>
                                        EmailValidator.validate(value!) ||
                                        translations.commonForm.errors.validation.email,
                                })}
                                error={errors.epost?.message}
                            />
                        </div>
                        {isGardUtenDrift && (
                            <div className="melde-interesse-skjema-input-row">
                                <Input
                                    className="melde-interesse-skjema-input-row__input-field"
                                    label={translations.meldInteresse.form.address}
                                    {...register('adresse', {
                                        disabled: !isGardUtenDrift,
                                        required: {
                                            value: isGardUtenDrift,
                                            message: translations.meldInteresse.form.errors.required.address,
                                        },
                                    })}
                                    error={errors.adresse?.message}
                                />
                                <Input
                                    className="melde-interesse-skjema-input-row__input-field"
                                    placeholder="1234 Sted"
                                    label={translations.meldInteresse.form.postalcode}
                                    {...register('postnummer', {
                                        disabled: !isGardUtenDrift,
                                        required: {
                                            value: isGardUtenDrift,
                                            message: translations.meldInteresse.form.errors.required.postalcode,
                                        },
                                    })}
                                    error={errors.postnummer?.message}
                                />
                            </div>
                        )}
                        {(meldinteresseconfig.activatecheckbox || meldinteresseconfig.activateandhidecheckbox) && (
                            <Controller
                                name="fritekstfelt"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <MeldeInteresseCheckboxAndFritekstfelt
                                        onChange={onChange}
                                        hideCheckbox={meldinteresseconfig.activateandhidecheckbox}
                                        error={errors.fritekstfelt}
                                    />
                                )}
                            />
                        )}
                        {interesseskjemaError && (
                            <ErrorMessage
                                text={
                                    interesseskjemaError?.status === STATUS_BAD_REQUEST
                                        ? translations.errors.badRequestMessage
                                        : translations.errors.defaultMessage
                                }
                            />
                        )}
                        <div className="melde-interesse-skjema-form__kontakt-meg">
                            <p className="melde-interesse-skjema-form__kontakt-meg__privacy">
                                <span>{translations.meldInteresse.contact.privacyText}</span>
                                <a href={links.gdprURL.url}>{translations.meldInteresse.contact.linkText}</a>
                            </p>
                            <div>
                                <ButtonWithSpinner
                                    buttonType={ButtonTypes.STANDARD_POSITIVE}
                                    type="submit"
                                    showSpinner={interesseskjemaIsSubmitting}
                                    data-track-id="modal-meld-interesse-skjema-innsendt"
                                    data-track-linkname={meldinteresseconfig.produktkode || 'ukjent-produkt'}
                                >
                                    {sitecoreTranslations.submitbuttonname}
                                </ButtonWithSpinner>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </Modal>
    );
};

export default MeldeInteresseSkjemaModal;
