import React, { MutableRefObject, Ref, useEffect, useRef, useState } from 'react';

import { Button, LinkButton } from '@eika/button';
import { IconTypes } from '@eika/icon';
import { ButtonToggles } from '@eika/radio-button';

import { useConfig } from '../../default-config';
import useTranslation from '../../hooks/useTranslation';
import TranslationsConfigType from '../../translations/translationType';

interface DuirationPickerProps {
    duration: string | undefined;
    setDuration: (newDuration: string | undefined) => void;
    prev: () => void;
    next: () => void;
}

type Duration = {
    value: string;
    label?: string;
    id?: string;
    ref?: Ref<HTMLButtonElement>;
    ariaLabel?: string;
};

const cutoffPoint = 3;

const getAriaLabel = (label: string, translation: TranslationsConfigType): string | undefined => {
    return label.includes('mnd')
        ? `${label.split(' ')[0]} ${label.split(' ')[0] === '1' ? translation.month : translation.months}`
        : undefined;
};

const DurationPicker: React.FC<DuirationPickerProps> = ({ duration, setDuration, prev, next }) => {
    const translation = useTranslation();
    const { subjects } = useConfig();
    const hasSubjects = Object.entries(subjects).length > 0;
    const elementToFocus = useRef<HTMLButtonElement>(null);
    const durationArray: Duration[] = hasSubjects
        ? Object.entries(subjects).map((arr, i) => ({
              label: arr[1],
              value: arr[0],
              ref: i === cutoffPoint ? (elementToFocus as MutableRefObject<HTMLButtonElement>) : undefined,
              ariaLabel: getAriaLabel(arr[1], translation),
          }))
        : [
              {
                  label: '3 mnd',
                  value: '3mnd',
                  ariaLabel: `3 ${translation.months}`,
              },
          ];

    const durationOutOfVisibleButtonRange =
        hasSubjects &&
        durationArray.slice(0, cutoffPoint).find((x) => x.value === duration) === undefined &&
        durationArray.find((x) => x.value === duration) !== undefined;

    const [showMore, setShowMore] = useState(durationOutOfVisibleButtonRange);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const buttonTogglesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (buttonTogglesRef.current) {
            const el = buttonTogglesRef.current.querySelector('.sgw-radio-button-legend') as HTMLSpanElement;
            el?.setAttribute('tabindex', '-1');
            el?.focus();
        }
    }, [buttonTogglesRef]);

    useEffect(() => {
        if (showMore && !durationOutOfVisibleButtonRange && elementToFocus.current) {
            elementToFocus.current.focus();
        }
    }, [showMore, durationOutOfVisibleButtonRange, elementToFocus]);

    const changeDuration = (value: string) => {
        setErrorMessage(undefined);
        setDuration(value);
    };

    const goNext = () => {
        if (duration === undefined || duration === '') {
            setErrorMessage(translation.avdragsfrihet.form.chooseDurationError);
            return;
        }
        next();
    };

    return (
        <section className="avdragsfrihet-form__content" ref={buttonTogglesRef}>
            <ButtonToggles
                className="avdragsfrihet-form__duration-picker"
                label={translation.avdragsfrihet.form.durationTitle}
                options={showMore ? durationArray : durationArray.slice(0, cutoffPoint)}
                selectedOption={duration}
                onChange={changeDuration}
                errorMessage={errorMessage}
            />
            {hasSubjects && durationArray.length > 3 && !showMore && (
                <LinkButton
                    className="avdragsfrihet-form__show-more-button"
                    icon={IconTypes.PLUSS_24}
                    onClick={() => setShowMore(true)}
                    aria-expanded={showMore}
                    iconOnRightSide
                >
                    {translation.avdragsfrihet.form.durationShowMoreOptions}
                </LinkButton>
            )}
            <div className="avdragsfrihet-form__button-group avdragsfrihet-form__item-centered">
                <Button buttonType="negative" onClick={prev}>
                    {translation.avdragsfrihet.form.previous}
                </Button>
                <Button onClick={goNext}>{translation.avdragsfrihet.form.next}</Button>
            </div>
        </section>
    );
};

export default DurationPicker;
