import { useEffect, useState } from 'react';

const useQueryParam = (key: string): [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>] => {
    const [value, setValue] = useState<string | undefined>();
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        const param = params.get(key);
        if (!!param && (!value || value !== param)) {
            setValue(param);
        }
    }, [value, key]);
    return [value, setValue];
};

export default useQueryParam;
